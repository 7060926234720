var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "agent-form" },
        [
          _c("el-header", [
            _c(
              "div",
              {
                staticClass: "logo-container",
                style: { color: _vm.currentTheme.logoTextColor },
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/images/logo1.png"), alt: "" },
                }),
                _c("span", [_vm._v("Bunker Chain")]),
              ]
            ),
          ]),
          _vm.isValid
            ? _c(
                "div",
                { staticClass: "container" },
                [
                  _vm._m(0),
                  _c("p", { staticClass: "one-tip" }, [
                    _vm._v(
                      "Please fill in the following information to complete the registration."
                    ),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: "userForm",
                      attrs: {
                        "label-position": "top",
                        "label-width": "230px",
                        model: _vm.userForm,
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Email", prop: "email" } },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.userForm.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userForm, "email", $$v)
                                      },
                                      expression: "userForm.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "IMO", prop: "imo" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.userForm.imo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userForm, "imo", $$v)
                                      },
                                      expression: "userForm.imo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Ship Name",
                                    prop: "shipName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.userForm.shipName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userForm, "shipName", $$v)
                                      },
                                      expression: "userForm.shipName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Type", prop: "type" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t("pleaseSelect"),
                                      },
                                      model: {
                                        value: _vm.userForm.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.userForm, "type", $$v)
                                        },
                                        expression: "userForm.type",
                                      },
                                    },
                                    _vm._l(_vm.typeOptions, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.value,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Callsign",
                                    prop: "callSign",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.userForm.callSign,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userForm, "callSign", $$v)
                                      },
                                      expression: "userForm.callSign",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Gross Registered Tonnage",
                                    prop: "grt",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.userForm.grt,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userForm, "grt", $$v)
                                      },
                                      expression: "userForm.grt",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Port of Registry",
                                    prop: "registryPort",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.userForm.registryPort,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userForm,
                                          "registryPort",
                                          $$v
                                        )
                                      },
                                      expression: "userForm.registryPort",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "License No.",
                                    prop: "licenceNo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.userForm.licenceNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userForm, "licenceNo", $$v)
                                      },
                                      expression: "userForm.licenceNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Cell Phone Number",
                                    prop: "cellPhone",
                                  },
                                },
                                [
                                  _c("phone-input", {
                                    model: {
                                      value: _vm.userForm.cellPhone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userForm, "cellPhone", $$v)
                                      },
                                      expression: "userForm.cellPhone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Satellite Phone Number",
                                    prop: "satellitePhone",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.userForm.satellitePhone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userForm,
                                          "satellitePhone",
                                          $$v
                                        )
                                      },
                                      expression: "userForm.satellitePhone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Password",
                                    prop: "password",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "password",
                                      "show-password": "",
                                    },
                                    model: {
                                      value: _vm.userForm.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userForm, "password", $$v)
                                      },
                                      expression: "userForm.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Confirm Password",
                                    prop: "confirmPassword",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "password",
                                      "show-password": "",
                                    },
                                    model: {
                                      value: _vm.userForm.confirmPassword,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userForm,
                                          "confirmPassword",
                                          $$v
                                        )
                                      },
                                      expression: "userForm.confirmPassword",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Ex-name", prop: "exName" } },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.userForm.exName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.userForm, "exName", $$v)
                                      },
                                      expression: "userForm.exName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Seal Verification Report No.",
                                    prop: "sealVerificationReportNo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value:
                                        _vm.userForm.sealVerificationReportNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userForm,
                                          "sealVerificationReportNo",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "userForm.sealVerificationReportNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Meter Serial No.",
                                    prop: "meterSerialNo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.userForm.meterSerialNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userForm,
                                          "meterSerialNo",
                                          $$v
                                        )
                                      },
                                      expression: "userForm.meterSerialNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Ship Stamp" } },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      attrs: {
                                        action: _vm.fileUploadUrl,
                                        accept: ".jpg,.png",
                                        "list-type": "picture",
                                        "on-change": _vm.onUploadStamp,
                                        "auto-upload": false,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "min-width": "200px",
                                            "font-size": "14px",
                                          },
                                          attrs: {
                                            type: "primary",
                                            plain: "",
                                            round: "",
                                          },
                                        },
                                        [_vm._v("Upload")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "el-upload__tip",
                                          staticStyle: { margin: "0" },
                                          attrs: { slot: "tip" },
                                          slot: "tip",
                                        },
                                        [
                                          _vm._v(
                                            "Only JPEG/PNG can be uploaded."
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "div-stamp-preview" }, [
                              _vm.croppedStampFile
                                ? _c("img", {
                                    attrs: { src: _vm.croppedStampFile.url },
                                  })
                                : _c("div", [_vm._v("No stamp uploaded")]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "btns" },
                    [
                      _c(
                        "el-button",
                        { staticClass: "btn", on: { click: _vm.resetForm } },
                        [_vm._v(_vm._s(_vm.$t("reset")))]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: {
                            type: "primary",
                            loading: _vm.submitLoading,
                          },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v(_vm._s(_vm.$t("submit")))]
                      ),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "one-tip" }, [
                    _vm._v("Many thanks and have a great day ahead !"),
                  ]),
                  _c("p", { staticClass: "one-tip" }, [
                    _vm._v(
                      "By uploading and accepting the conditions or uploading any documents, you confirm and agree to the binding usage of Digital agreements of Bunkerchain Pte Ltd, if you have received this e-mail by mistake, please inform the user immediately or send a technical request to support@bunkerchain.io. Bunkerchain Pte Ltd reserve all rights, for a copy of our terms & conditions, please visit our website at www.bunkerchain.io, or request with the user administrator. Please note that any views or opinions presented in this email are solely those of the author and do not necessarily represent those of the company. Finally, the recipient should check this email and any attachments for the presence of viruses. The company accepts no liability for any damage caused by any virus transmitted by this email."
                    ),
                  ]),
                ],
                1
              )
            : _c("div", { staticClass: "no-valid" }, [
                _vm._v(" " + _vm._s(_vm.$t("pageExpired")) + " "),
              ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isCropStamp,
            title: "Ship Stamp",
            width: "50%",
            "close-on-click-modal": false,
          },
          on: {
            close: function ($event) {
              _vm.isCropStamp = false
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isStampLoading,
                  expression: "isStampLoading",
                },
              ],
              staticStyle: {
                "min-height": "100px",
                display: "flex",
                "justify-content": "center",
              },
            },
            [
              _c("cropper", {
                ref: "cropper",
                staticClass: "cropper",
                attrs: { src: (_vm.stampFile && _vm.stampFile.url) || "" },
                on: {
                  change: _vm.handleStampCropChange,
                  ready: _vm.handleCropperReady,
                },
              }),
            ],
            1
          ),
          !_vm.isStampLoading
            ? _c(
                "div",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { round: "" },
                      on: {
                        click: function ($event) {
                          _vm.isCropStamp = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        round: "",
                        type: "primary",
                        loading: _vm.isStampBtnLoading,
                      },
                      on: { click: _vm.handleUploadStampOnClick },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("upload")) + " ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "email-detail" }, [
      _c("p", [_vm._v("Dear Sirs,")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }